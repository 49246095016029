<template>


  <div>CORPORATE</div>

</template>


<script>

export default {
  name: 'Corporate',
  metaInfo() {
    return {
      title: 'Corporate | Magenta Delivery'
    }
  },
}

</script>