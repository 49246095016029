<template>

  <div class="general static socialmedia">

    <h1>Rimani sempre aggiornato</h1>

    <div class="ec_static_page_container">

      <div class="ec_static_icon_block">
        <h2 class="iconized">
          <v-icon>mdi-facebook</v-icon>
          Facebook
        </h2>

        <span class="bigger">/magentadelivery</span>
      <p></p>
      </div>

      <div class="ec_static_icon_block">
        <h2 class="iconized">
          <v-icon>mdi-instagram</v-icon>
          Instagram
        </h2>

        <span class="bigger">@magentadelivery</span>
        <p>Seguici online, condividi i tuoi ordini e taggaci nelle tue storie!</p>
      </div>

      <h3>Sede legale</h3>

      <p>TREPUNTOZERO
        <br>via Pusterla 8, Magenta, MI
        <br>Partita IVA: 08238620960
      </p>

    </div>

  </div>

</template>

<script>

export default {
  name: 'Social Media',
  metaInfo() {
    return {
      title: 'Social Media | Magenta Delivery'
    }
  },
}

</script>

<style>

.general.socialmedia {
  min-height: 71vh;
}

</style>