<template>


  <div>TERMS</div>

</template>


<script>

export default {
  name: 'Terms',
  metaInfo() {
    return {
      title: 'Terms & Conditions | Magenta Delivery'
    }
  },
}

</script>