<template>

  <v-container>

CIAO
    <div>
      <faq-accordion></faq-accordion>
    </div>


  </v-container>

</template>





<script>

  import FaqAccordion from "../../components_frontend/Home/faq_accordion";

  export default {
    name: 'FAQ',
    metaInfo() {
      return {
        title: 'FAQ | Magenta Delivery'
      }
    },
    components: {
      FaqAccordion
    }
  }

</script>


<style>


</style>
