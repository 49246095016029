<template>

  <div>

    <iframe class="ec_iframe_full" src="https://mailchi.mp/magdel/vendors-signup"></iframe>

  </div>

</template>


<script>

export default {
  name: 'Register as vendor',
  metaInfo() {
    return {
      title: 'Register as Vendor | Magenta Delivery'
    }
  },
}

</script>