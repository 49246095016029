<template>


  <div>VENDOR PORTAL</div>

</template>



<script>

export default {
  name: 'VendorPortal',
  metaInfo() {
    return {
      title: 'Vendor Portal | Magenta Delivery'
    }
  },
}

</script>