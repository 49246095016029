<template>

  <div class="general static contact">

    <h1>Entra in contatto</h1>

    <div class="ec_static_page_container">

      <div class="ec_static_icon_block">
        <h2 class="iconized">
        <v-icon>mdi-phone-classic</v-icon>
          Assistenza clienti:
        </h2>

        <span class="bigger">02 8239 8526</span>

        <p>Il numero è sempre attivo.<br>
          Gli operatori rispondono tutti i giorni dalle 11:00 alle 14:30 e dalle 18:30 alle 21:30, ma puoi lasciare un messaggio in qualsiasi momento.</p>

      </div>

      <div class="ec_static_icon_block">
        <h2 class="iconized">
          <v-icon>mdi-email-newsletter</v-icon>
          Indirizzo email:
        </h2>

        <span class="bigger">info@magdel.it</span>

        <p>Il tempo di risposta medio è di 24 ore.</p>

      </div>

      <v-card class="ec_static_card">

      <h2>Hai un'attività e vuoi aderire gratuitamente?</h2>
      <h2 class="colored">Ora più che mai, facciamo rete.</h2>

      <p>Richiedi subito di aderire al primo portale dedicato alla digitalizzazione completa dell'esperienza di ordinazione.</p>

        <h3>Non solo consegna a domicilio:</h3>

        <ul>
          <li>Menu digitali temporizzati</li>
          <li>Automazione degli appuntamenti</li>
          <li>Gestione delle prenotazioni</li>
          <li>Interfaccia NFC e QR</li>
          <li>Sistemi avanzati di promozione locale</li>
        </ul>

        <h3>Cosa devi sapere?</h3>

      <ul>
        <li>L’adesione è sempre gratuita</li>
        <li>L’adesione non è automatica, ma è soggetta ad approvazione da parte di magentadelivery.it sulla base dei requisiti di qualità richiesti.</li>
      </ul>

        <h3>Come funziona magentadelivery.it</h3>

      <ul>
        <li>Gli utenti del territorio interessato accedono a magentadelivery.it</li>
        <li>Selezionando il venditore ne visualizzano la proposta direttamente online.</li>
        <li>Dopo aver aggiunto al carrello i prodotti desiderati, il pagamento viene effettuato online ed in tutta sicurezza.</li>
        <li>Il cliente sceglie la modalità di ricezione del proprio ordine: a domicilio, in azienda, con ritiro d'asporto o con consumazione sul posto, attraverso la nostra opzione di prenotazione del posto.</li>
      </ul>

        <h3>Sede legale</h3>

      <p>TREPUNTOZERO
        <br>via Pusterla 8, Magenta, MI
        <br>Partita IVA: 08238620960
        <br><br>
        Il progetto magentadelivery.it è possibile grazie alla joint venture Italo-Tedesco tra TREPUNTOZERO e IMBAA Werbeagentur.
      </p>

      </v-card>

    </div>

  </div>

</template>

<script>

export default {
  name: 'Contact',
  metaInfo() {
    return {
      title: 'Contact | Magenta Delivery'
    }
  },
}

</script>