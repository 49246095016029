<template>


  <div>COOKIES</div>

</template>


<script>

export default {
  name: 'Cookies',
  metaInfo() {
    return {
      title: 'Cookies | Magenta Delivery'
    }
  },
}

</script>