<template>


    <div class="py-5">


        <v-btn @click="resetCookieSettings()" small depressed>Reimpostare le impostazioni dei cookie</v-btn>

    </div>

</template>


<script>

    export default{

        name: 'TrackingSettings',
        methods: {

            resetCookieSettings: function(){


               localStorage.removeItem('cookie:accepted');
               location.reload();

            }

        }

    }

</script>