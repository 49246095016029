<template>

  <div class="general static jobs">

    <h1>Invia la tua candidatura</h1>

    <div class="ec_static_page_container">

      <div class="ec_static_icon_block">
        <h2 class="iconized">
          <v-icon>mdi-motorbike</v-icon>
          per ritiri e consegne
        </h2>

        <span class="bigger">Riders</span>
        <p>Seguici online, condividi i tuoi ordini e taggaci nelle tue storie!</p>
      </div>

      <div class="ec_static_icon_block">
        <h2 class="iconized">
          <v-icon>mdi-school</v-icon>
          per opportunità di tirocinio
        </h2>

        <span class="bigger">Studenti</span>
        <p>Seguici online, condividi i tuoi ordini e taggaci nelle tue storie!</p>
      </div>

      <div class="ec_static_icon_block">
        <h2 class="iconized">
          <v-icon>mdi-code-braces-box</v-icon>
          per lo sviluppo tecnico
        </h2>

        <span class="bigger">Programmatori</span>
        <p>Seguici online, condividi i tuoi ordini e taggaci nelle tue storie!</p>
      </div>

      <div class="ec_static_icon_block">
        <h2 class="iconized">
          <v-icon>mdi-handshake</v-icon>
          per la rete commerciale
        </h2>

        <span class="bigger">Rappresentanti</span>
        <p>Seguici online, condividi i tuoi ordini e taggaci nelle tue storie!</p>
      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: 'Jobs',
  metaInfo() {
    return {
      title: 'Jobs | Magenta Delivery'
    }
  },
}

</script>